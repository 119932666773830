<template>
  <b-card>
    <div>
      <!-- User Info: Input Fields -->
      <validation-observer ref="formStaff">
        <b-form @submit.prevent="handleSubmit">
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <h4 class="mb-0">Thông tin mã tác động</h4>
          </div>
          <b-row class="mt-1">
            <b-col>
              <validation-provider #default="{ errors }" name="code" rules="required">
                <b-form-group label="Mã tác động" label-for="action-code-code">
                  <b-form-input id="action-code-code" v-model="actionCode.code" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col>
              <validation-provider #default="{ errors }" name="name" rules="required">
                <b-form-group label="Diễn giải" label-for="action-code-name">
                  <b-form-input id="action-code-name" v-model="actionCode.name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="d-flex mt-2">
            <h4 class="mb-0">Thông tin đối tác</h4>
          </div>
          <small class="text-danger">{{ errorMessage }}</small>

          <b-row class="mt-1">
            <b-col>
              <action-code-partner
                :options="partnerOptions"
                :children="contractStatusOptions"
                @update-permission="updatePartners"
              />
            </b-col>
          </b-row>

          <b-row class="mt-2 justify-content-center">
            <!-- Action Buttons -->
            <b-button
              variant="outline-secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: constRouter.ACTION_CODE.name }"
            >
              Hủy
            </b-button>
            <b-overlay :show="loading" block opacity="0.6" spinner-variant="primary">
              <b-button type="submit" variant="primary" block> Thêm </b-button>
            </b-overlay>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BMedia,
  BOverlay,
  BRow,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import constRouter from '@/constants/constRouter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';
import { actionCodeCreate, actionCodeListPartner } from '@/apis/apiActionCode';
import { contractStatuses } from '@/apis/general';
import ActionCodePartner from '@/views/action-code/ActionCodePartner.vue';
import useActionCode from '@/views/action-code/useActionCode';

export default {
  components: {
    ActionCodePartner,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    BCard,
    BAvatar,
    BMedia,
    BInputGroupAppend,

    flatPickr,

    ValidationProvider,
    ValidationObserver,

    vSelect,
    BOverlay,
  },
  setup() {
    const { partners, errorMessage, updatePartners, validate } = useActionCode();

    return { partners, errorMessage, updatePartners, validate };
  },
  data: () => ({
    constRouter,
    required,
    loading: false,
    actionCode: {
      name: undefined,
      code: undefined,
      partnerId: undefined,
      contractStatusId: undefined,
    },
    partnerOptions: [],
    contractStatusOptions: [],
    errorMesage: '',
  }),
  created() {
    this.fetchOptionsSelect();
  },
  methods: {
    fetchOptionsSelect() {
      const partnerOptionsApi = actionCodeListPartner();
      const contractStatusOptionsApi = contractStatuses();

      Promise.all([partnerOptionsApi, contractStatusOptionsApi]).then((res) => {
        const partnerOptions = res[0]?.data.data;
        const contractStatusOptions = res[1]?.data;

        this.partnerOptions = partnerOptions.map((partnerOption) => ({
          id: partnerOption.id,
          name: partnerOption.name,
          code: partnerOption.id,
        }));
        this.contractStatusOptions = Object.keys(contractStatusOptions).map((item, index) => ({
          code: item,
          name: contractStatusOptions[item],
        }));
      });
    },
    handleSubmit() {
      const form = this.$refs.formStaff;
      form.validate().then((success) => {
        if (success) {
          if (!this.validate()) {
            return;
          }

          this.loading = true;
          const { name, code, partnerId, contractStatusId } = this.actionCode;

          const payload = {
            name,
            code,
            partners: this.partners,
          };

          actionCodeCreate(payload)
            .then((res) => {
              this.loading = false;
              const { data } = res.data;
              this.$router
                .push({ name: constRouter.ACTION_CODE_VIEW.name, params: { id: data?.id } })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Thêm mã tác động',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      text: 'Thêm mã tác động thành công!',
                    },
                  });
                });
            })
            .catch((err) => {
              this.loading = false;
              if (err.response.status === 422) {
                form.setErrors(err.response.data.errors);
              }
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Thêm mã tác động',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Thêm mã tác động thất bại!',
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
