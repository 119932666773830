<template>
  <b-card class="permission-list px-2">
    <!-- search input -->
    <div class="d-flex mb-1">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input v-model.lazy="searchword" placeholder="Tìm kiếm" @input="search" />
      </b-input-group>
    </div>
    <!-- tree -->
    <div class="position-relative">
      <div
        v-if="disableCheck"
        class="position-absolute"
        style="
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: transparent;
          z-index: 999;
          cursor: not-allowed;
        "
      ></div>
      <v-tree
        ref="tree"
        class="list-permission"
        :data="data"
        :draggable="true"
        :halfcheck="true"
        :multiple="true"
        :tpl="tpl"
        @node-check="handleCheckNode"
      />
    </div>
  </b-card>
</template>

<script>
import { VTree } from 'vue-tree-halower';
import { ref, toRefs, watch } from '@vue/composition-api';
import {
  BOverlay,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BCard,
  BButton,
  BFormGroup,
} from 'bootstrap-vue';

export default {
  components: {
    VTree,
    BOverlay,
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BButton,
  },
  props: {
    // [{
    //    "id": "93dcaae4-bfd7-47d8-aa89-fe118e89fb44",
    //    "permissionId": "93dcaae4-bfd7-47d8-aa89-fe118e89fb44",
    //    "limited": "owner", // owner | team | all
    //    "name": "Thêm quyền hạn cho nhân viên",
    //    "group": "staffs",
    // }],
    perCheck: {
      type: Array,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    children: {
      type: Array,
      default: null,
    },
    roleName: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableCheck: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // danh sach permission cua he thong
    const { perCheck } = toRefs(props);
    const { options } = toRefs(props);
    const { children } = toRefs(props);
    const data = ref([]);
    const treeOptions = ref([]);

    const groupPermission = (listPer) => {
      const res = [];
      const formatArrayPer = listPer?.reduce((result, item) => {
        result[item.group] = result[item.group] || [];
        result[item.group].push(item);
        return result;
      }, {});
      if (formatArrayPer) {
        for (const [key, value] of Object.entries(formatArrayPer)) {
          res.push({
            group: key,
            value,
          });
        }
      }
      return res;
    };

    const createChildren = (listChild, perRole) => {
      const res = [];
      listChild.forEach((child) => {
        const role = perRole?.find((r) => r.id === child.id) || {};
        const childrenTemp = children.value.map((item) => ({
          id: `${item.code}|${child.id}`,
          checked: role.result === item.code,
          title: item.name,
        }));
        res.push({
          title: child.name,
          // id: child.id,
          checked: !!role.id,
          expanded: !!role.id,
          children: childrenTemp,
        });
        // res.push({
        //   title: child.name,
        //   // id: child.id,
        //   checked: !!role.id,
        //   expanded: true,
        //   children: [
        //     {
        //       id: `owner_${child.id}`,
        //       checked: role.limited === 'owner',
        //       title: 'Cá nhân',
        //     },
        //     {
        //       id: `team_${child.id}`,
        //       checked: role.limited === 'team',
        //       title: 'Nhóm',
        //     },
        //     {
        //       id: `all_${child.id}`,
        //       checked: role.limited === 'all',
        //       title: 'Tất cả',
        //     },
        //   ],
        // });
      });
      return res;
    };

    const updatePermissionsCheck = (value) => {
      data.value = [];
      // permission cua role
      value = groupPermission(value);
      treeOptions.value.forEach((item) => {
        const perRole = value.find((val) => val.group === item.group) || {};
        // permission duoc check
        if (perRole.group) {
          data.value.push(...createChildren(item.value, perRole.value));
        } else {
          // permission khong duoc check
          data.value.push(...createChildren(item.value, []));
        }
      });
    };

    // const getPermission = () => {
    //   permissionList()
    //     .then((res) => {
    //       permissions.value = groupPermission(res.data.data);
    //
    //       updatePermissionsCheck(perCheck.value);
    //     })
    //     .catch((err) => console.log(err));
    // };

    // fetch list permissions
    // getPermission();
    watch([options], (value) => {
      if (options.value) {
        treeOptions.value = groupPermission(options.value);

        updatePermissionsCheck(perCheck.value);
      }
    });
    watch([perCheck], (value) => {
      if (options.value) {
        treeOptions.value = groupPermission(options.value);
      }
      updatePermissionsCheck(value);
    });

    return {
      data,
      permissions: treeOptions,
      groupPermission,
      createChildren,
    };
  },
  data() {
    return {
      searchword: '',
      debounce: null,
    };
  },
  methods: {
    handleCheckNode() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.processDataAndEmit();
      }, 400);
    },
    handleClick() {
      this.processDataAndEmit();
    },
    processDataAndEmit() {
      // let data = [];
      let arrayPermissionCheck = this.$refs.tree.getCheckedNodes();
      arrayPermissionCheck = arrayPermissionCheck.reduce((filter, per) => {
        if (per.id) {
          const item = { parentId: per.id.split('|')[1], childrenId: per.id.split('|')[0] };
          filter.push(item);
        }
        return filter;
      }, []);
      this.$emit('update-permission', arrayPermissionCheck);
    },
    search() {
      this.$refs.tree.searchNodes(this.searchword);
    },
    tpl(...args) {
      const { 0: node, 2: parent, 3: index, 4: props } = args;
      let titleClass = node.checked ? 'node-title node-selected' : 'node-title';
      if (node.searched) titleClass += ' node-searched';
      if (props.level === 2 && node.checked === true) {
        parent.children.forEach((element, idx) => {
          if (index !== idx) {
            element.checked = false;
          }
        });
        parent.checked = true;
      }
      return (
        <span>
          <span
            class={titleClass}
            domPropsInnerHTML={node.title}
            // onClick={() => {
            //   this.$refs.tree.nodeSelected(node);
            // }}
          ></span>
        </span>
      );
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/tree.scss';
.permission-list {
  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .list-permission {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .node-title {
      padding: 0;
      margin: 0;
    }
    .halo-tree {
      .node-title {
        padding: 0;
        margin: 0;
      }
      .check {
        left: -2px;
      }
      li::before {
        display: block;
      }
      li::after {
        display: block;
        width: 7px;
      }
      li {
        width: 100%;
      }
      // .leaf {
      //   display: inline-block;
      //   width: 33%;
      // }
    }
    li::before,
    li::after {
      display: none;
    }
    li {
      width: 30%;
      padding-left: 0;
      margin-left: 15px;
    }
    .inputCheck {
      &.box-checked:after {
        background-color: $primary;
        color: #fff;
      }
    }
  }
}
</style>
