import { ref } from '@vue/composition-api';

export default function useActionCode() {
  const partners = ref([]);
  const errorMessage = ref('');

  /**
   * @param data: [{parentId,childrenId}]
   */
  const updatePartners = (data) => {
    if (data && data.length > 0) {
      partners.value = data.map((item) => ({
        partner_id: item.parentId,
        result: item.childrenId,
      }));
    } else {
      partners.value = [];
    }
  };

  const validate = () => {
    let result = true;
    if (!(partners.value && partners.value.length > 0)) {
      errorMessage.value = 'Chưa nhập thông tin đối tác';
      result = false;
    }
    return result;
  };

  return {
    partners,
    errorMessage,

    updatePartners,
    validate,
  };
}
