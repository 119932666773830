import jwt from '@/auth/jwt/useJwt';
import { removeEmptyString } from '@/common/utils';

export function actionCodeList(condition) {
  return jwt.axiosIns.get('/action-codes', {
    params: {
      ...condition,
    },
  });
}

export function actionCodeListPartner(condition) {
  return jwt.axiosIns.get('/action-codes/partners', {
    params: {
      ...condition,
    },
  });
}
export function actionCodeListLender(condition) {
  return jwt.axiosIns.get('/action-codes/users', {
    params: {
      ...condition,
    },
  });
}

export function actionCodeShow(id) {
  return jwt.axiosIns.get(`/action-codes/${id}`);
}

export function actionCodeShowCampaign(id) {
  return jwt.axiosIns.get(`/action-codes/${id}/campaigns`);
}

export function actionCodeRemove(id) {
  return jwt.axiosIns.delete(`/action-codes/${id}`);
}

export function actionCodeCreate(data) {
  data = removeEmptyString(data);
  return jwt.axiosIns.post('action-codes', data);
}

export function actionCodeUpdate(id, data) {
  data = removeEmptyString(data);
  return jwt.axiosIns.put(`/action-codes/${id}`, data);
}
