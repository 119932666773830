import jwt from '@/auth/jwt/useJwt';

export function structure() {
  return jwt.axiosIns.get('general/structure');
}

export function types() {
  return jwt.axiosIns.get('general/types');
}

export function contractStatuses() {
  return jwt.axiosIns.get('general/contract-statuses');
}

export function getPersonContact() {
  return jwt.axiosIns.get('general/person-contacts');
}

export function getPlaceContract() {
  return jwt.axiosIns.get('general/place-contacts');
}
